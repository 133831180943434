import {
  Alert,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Form, useNavigation } from '@remix-run/react';
import { COLORS } from '@tbd/life-tokens';
import { AppTitle } from '~/components/AppTitle';

import { PasswordInput } from '@tbd/lift-ui/components/PasswordInput';
import { TextButton } from '@tbd/lift-ui/components/TextButton';

export interface SignInProps {
  action: string;
  redirectTo?: string;
  errors?: Record<string, string>;
  forgotPassword?: boolean;
}

export function SignIn(props: SignInProps) {
  const { action, errors = {}, redirectTo } = props;

  const navigation = useNavigation();
  const theme = useTheme();
  const usernameError = errors?.username;
  const hasUsernameError = Boolean(usernameError);
  const passwordError = errors?.password;
  const hasPasswordError = Boolean(passwordError);

  return (
    <>
      {'login' in errors ? (
        <Alert
          sx={{
            ...theme.typography['component-bold-s'],
            backgroundColor: COLORS.critical_100,
            width: 'auto',
            marginBottom: '32px',
          }}
          severity="error"
        >
          {errors.login}
        </Alert>
      ) : null}
      <AppTitle
        color="white"
        variant="header-xs"
        sx={{ color: COLORS.black }}
      />
      <Form replace method="post" action={action}>
        <Stack direction="column" gap={2}>
          <input type="hidden" name="redirectTo" value={redirectTo} />
          <Stack>
            <InputLabel error={hasUsernameError} htmlFor="username">
              <Typography variant="component-regular-xxs">
                Username or email address
              </Typography>
            </InputLabel>
            <OutlinedInput
              autoComplete="username"
              autoFocus
              error={hasUsernameError}
              fullWidth
              id="username"
              name="username"
              placeholder="e.g. annie@laerdal.com"
              size="small"
              sx={{ p: 1, backgroundColor: 'white' }}
              type="text"
              inputProps={{
                title: 'Between 3-64 characters; no spaces',
                minLength: 3,
                maxLength: 64,
                pattern: '^\\S+$',
              }}
            />
            {hasUsernameError ? (
              <Alert
                severity="error"
                sx={{ backgroundColor: COLORS.critical_100, my: 1 }}
              >
                {usernameError}
              </Alert>
            ) : null}
          </Stack>

          <Stack>
            <InputLabel error={hasPasswordError} htmlFor="password">
              <Typography variant="component-regular-xxs">Password</Typography>
            </InputLabel>
            <PasswordInput
              autoComplete="current-password"
              placeholder="Enter your password"
              error={hasPasswordError}
              sx={{ backgroundColor: 'white' }}
            />
            {hasPasswordError ? (
              <Alert
                severity="error"
                sx={{ backgroundColor: COLORS.critical_100, my: 1 }}
              >
                {passwordError}
              </Alert>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <TextButton
              size="small"
              href="/forgot-password"
              variant="tertiary"
              text="Forgot password?"
            />
          </Stack>
          <Stack>
            <TextButton
              type="submit"
              size="medium"
              variant="primary"
              disabled={navigation.state === 'submitting'}
              loading={navigation.state === 'submitting'}
              text="Sign in"
            />
          </Stack>
        </Stack>
      </Form>
    </>
  );
}
